<!--
 * @Descripttion : 日志查询
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-09-01 15:06:57
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-11 17:03:13
-->
<template>
  <div>
    <div style="padding:10px 0;">
      <a-form-model layout="inline" :model="formInline" @submit.native.prevent>
        <a-form-model-item>
          <a-range-picker style="width:240px;" @change="onTimeChange">
          </a-range-picker>
        </a-form-model-item>
        <a-form-model-item>
          <a-select v-model="formInline.flag" @change="getLogList">
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option value="1">
              成功
            </a-select-option>
            <a-select-option value="0">
              失败
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-input-search v-model="formInline.keyWord" placeholder="昵称" allowClear enter-button @search="getLogList" />
        </a-form-model-item>
      </a-form-model>
    </div>

    <a-table :columns="columns" bordered :data-source="data" :rowKey='record=>record.id' :pagination="pagination" :loading="loading" @change="handleTableChange" size="middle">
      <template slot="nickname" slot-scope="record">
        {{record.user.nickname}}
      </template>
      <template slot="flag" slot-scope="record">
        {{record.flag?"成功":"失败"}}
      </template>
    </a-table>

  </div>
</template>
<script>
import { getLogList } from "@/api/system";

const columns = [
  { title: "ID", dataIndex: "id", align: "center" },
  { title: "昵称", scopedSlots: { customRender: "nickname" }, align: "center" },
  { title: "模块", dataIndex: "module", align: "center" },
  { title: "状态", scopedSlots: { customRender: "flag" }, align: "center" },
  { title: "备注", dataIndex: "remark" },
  { title: "时间", dataIndex: "createTime", align: "center" }
];

export default {
  data() {
    return {
      columns,
      data: [],
      loading: false,
      pagination: {
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40"],
        showSizeChanger: true,
        current: 1,
        total: 0
      },
      formInline: {
        keyWord: "",
        flag: "",
        queryStartTime: null,
        queryEndTime: null
      }
    };
  },
  mounted() {
    this.getLogList();
  },
  methods: {
    getLogList() {
      const that = this;
      that.loading = true;
      getLogList({
        flag: that.formInline.flag,
        keyWord: that.formInline.keyWord,
        pageNum: that.pagination.current,
        pageSize: that.pagination.pageSize,
        queryEndTime: that.formInline.queryEndTime,
        queryStartTime: that.formInline.queryStartTime
      })
        .then(res => {
          that.loading = false;
          that.data = res.data.list;
          that.pagination.total = res.data.total;
        })
        .catch(err => {
          console.log(err);
          that.loading = false;
        });
    },
    onTimeChange(date, dateString) {
      console.log(date, dateString);
      if (dateString) {
        this.formInline.queryStartTime = dateString[0];
        this.formInline.queryEndTime = dateString[1];
      }
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination, filters, sorter);
      this.pagination = { ...pagination };
      this.getLogList();
    }
  }
};
</script>
<style lang="scss" scoped>
</style>